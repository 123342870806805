<template lang="pug">
.RootOlderAdult
  Header
  Home(v-if="view === 'Home'")
  Autenticate(v-if="view === 'Autenticate'")
  Atention(v-if="view === 'Atention'")
  Popup(v-if="view === 'Popup'")
  UploadFiles(v-if="view === 'UploadFiles'")
  ConfirmUpload(v-if="view === 'ConfirmUpload'")
</template>

<script>
import { mapActions, mapState } from "vuex";

// components
import Header from "./components/Header.vue";
import Home from "./Home.vue";
import Popup from "./Popup.vue";
import Autenticate from "./Autenticate.vue";
import Atention from "./Atention.vue";
import UploadFiles from "./UploadFiles.vue";
import ConfirmUpload from "./ConfirmUpload.vue";

export default {
  name: "RootOlderAdult",
  components: {
    Header,
    Home,
    Autenticate,
    Atention,
    Popup,
    UploadFiles,
    ConfirmUpload
  },
  mounted() {
    // tracking
    let trackingData = {
      uuid: window.person.id,
      accion: "Customer - Inicio",
      debmedia_turn_code: "",
      url_origen: window.location.href,
      origen: "oa",
      mensajes: ""
    };

    this.sendTracking(trackingData);
  },
  computed: {
    ...mapState({
      view: state => state.olderAdult.view
    })
  },
  methods: {
    ...mapActions({
      sendTracking: "virtualrow/handleTracking"
    })
  }
};
</script>

<style lang="scss">
body {
  overflow-x: hidden;
}

.ButtonContinue__text {
  font-size: 1.5rem;
}

.custom-control-label {
  max-width: 363.33px;
}

.custom-control-label::before {
  width: 38.35px !important;
  height: 40px !important;
  border: 0.988015px solid #081e3d !important;
}

.custom-control-label::after {
  top: 20px !important;
  left: -12px !important;
  transform: translate(-50%, -50%);
}

.custom-control-input {
  width: 36px;
  height: 40px;
  z-index: 7;
}

/* The container must be positioned relative: */
.custom-select-oa {
  position: relative;
}

.custom-select-oa select {
  display: none !important; /*hide original SELECT element: */
}

.select-selected {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 2rem;

  width: 100%;
  height: 66px;

  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  background: none;
  color: var(--color-dark);

  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  border-radius: 10px;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  padding: 8px 16px;
  cursor: pointer;
}

.select-selected p {
  margin: 0;
}

.select-items div {
  font-style: normal;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
}

.select-items div p {
  width: 100%;
  margin: 0;
  padding: 0.8rem 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  border-bottom: 0.673585px solid #b8b8b8;
  color: #66707e;
}

.select-arrow-active {
  border: #3290e0 solid 1px;
}

/* Style items (options): */
.select-items {
  opacity: 0;
  will-change: animation;
  animation: fadeInSelect 0.2s forwards ease-in-out;
  transition: 0.2s all;
  max-height: 392px;
  overflow-y: auto;
  position: absolute;
  top: 125%;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 15px;
  background: #ffffff;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes fadeInSelect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
